<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
            class="text-center"
          >
            <!-- Create Item -->
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="primary"
              class="me-3"
              @click="isSaveDialogOpen = true; showPurchasesDetailsField = false"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Add Product or Services</span>
            </v-btn>
          </v-col>

          <!-- Save Dialog -->
          <v-dialog
            v-model="isSaveDialogOpen"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="d-flex align-center">
                {{ localItem.id ? 'Edit' : 'New' }} Product or Service
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isSaveDialogOpen = false; saveForm.reset(); localItem = {}"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="saveForm"
                  v-model="saveValid"
                >
                  <p>Step 1: Enter general details</p>
                  <v-text-field
                    v-model="localItem.name"
                    outlined
                    dense
                    label="Name"
                    :rules="[validators.required]"
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                  <v-textarea
                    v-model="localItem.description"
                    outlined
                    rows="3"
                    label="Description"
                    hide-details="auto"
                    class="mb-6"
                  ></v-textarea>

                  <v-divider></v-divider>

                  <p class="my-4">
                    Step 2: Enter sales details
                  </p>
                  <v-autocomplete
                    v-model="localItem.sales_category_id"
                    label="Sales Category"
                    :items="salesCategories"
                    item-text="name"
                    item-value="id"
                    single-line
                    outlined
                    dense
                    hide-details
                    return-object
                    class="mb-6"
                    @change="localItem.sales_category_id = $event.id"
                  >
                    <template #append-item>
                      <div class="pa-0 mt-2 text-center append-select">
                        <v-btn
                          block
                          depressed
                          color="primary"
                          class="rounded-0"
                          @click="isSaveCategoryDialogOpen = true; categoryType = 'sales'; fetchCategoryGroups();"
                        >
                          Add new category
                        </v-btn>
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-model="localItem.unit_price"
                    outlined
                    dense
                    min="0"
                    type="number"
                    placeholder="Unit Price"
                    prefix="BND$"
                    hide-details="auto"
                    class="mb-4"
                  ></v-text-field>
                  <template v-if="subscribed('Expenses')">
                    <div class="mb-6">
                      <v-checkbox
                        v-if="!localItem.purchases_category_id"
                        v-model="showPurchasesDetailsField"
                        label="I am also buying this item from my vendors"
                        color="primary"
                        value="true"
                        hide-details
                      ></v-checkbox>
                    </div>

                    <template v-if="showPurchasesDetailsField || localItem.purchases_category_id">
                      <v-divider></v-divider>

                      <p class="my-4">
                        Step 3: Enter purchases details
                      </p>
                      <v-autocomplete
                        v-model="localItem.purchases_category_id"
                        label="Purchases Category"
                        :items="purchasesCategories"
                        item-text="name"
                        item-value="id"
                        single-line
                        outlined
                        dense
                        hide-details
                        return-object
                        class="mb-6"
                        @change="localItem.purchases_category_id = $event.id"
                      >
                        <template #append-item>
                          <div class="pa-0 mt-2 text-center append-select">
                            <v-btn
                              block
                              depressed
                              color="primary"
                              class="rounded-0"
                              @click="isSaveCategoryDialogOpen = true; categoryType = 'purchases'; fetchCategoryGroups();"
                            >
                              Add new category
                            </v-btn>
                          </div>
                        </template>
                      </v-autocomplete>
                      <v-text-field
                        v-model="localItem.cost_price"
                        outlined
                        dense
                        min="0"
                        type="number"
                        placeholder="Cost Price"
                        prefix="BND$"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>
                    </template>
                  </template>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="saveItemLoading"
                  :disabled="saveItemLoading || !saveValid"
                  @click="saveItem"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="3"
            lg="3"
            class="pr-sm-0 mx-auto"
          >
            <!-- filter category -->
            <v-select
              v-model="categoryFilter"
              :items="salesCategories"
              item-text="name"
              item-value="id"
              single-line
              outlined
              dense
              hide-details
              return-object
              clearable
              placeholder="Select Category"
              class="mr-0 mr-sm-3"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            md="4"
            lg="3"
            class="d-flex pl-sm-0 mx-auto"
          >
            <!-- Search Item -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Product or Service name
                </li>
                <li>
                  Product or Service description
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Products or Services"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchItems"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Item List -->
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options.sync="options"
        :items-per-page="100"
        :loading="listLoading"
        mobile-breakpoint="0"
        hide-default-footer
        fixed-header
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Category -->
        <template #[`item.category`]="{item}">
          <div class="d-flex align-center">
            <v-chip
              v-if="item.sales_category"
              small
              color="primary"
              class="v-chip-light-bg font-weight-semibold primary--text"
            >
              {{ item.sales_category.name }}
            </v-chip>

            <v-chip
              v-if="item.purchases_category"
              small
              color="warning"
              class="v-chip-light-bg font-weight-semibold warning--text"
            >
              {{ item.purchases_category.name }}
            </v-chip>
          </div>
        </template>

        <!-- Description -->
        <template #[`item.description`]="{item}">
          <div
            class="text-truncate"
            style="width: 500px;"
          >
            {{ item.description }}
          </div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="fetchItem(item.id); isSaveDialogOpen = true; showPurchasesDetailsField = false">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="localItem = item; isDeleteDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="items"
        :options.sync="options"
        :items-per-page="100"
        :loading="listLoading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        fixed-header
        class="text-no-wrap fixed-action d-block d-sm-none"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <div class="d-flex align-center my-2">
              <v-chip
                v-if="item.sales_category"
                small
                color="primary"
                class="mr-2 v-chip-light-bg font-weight-semibold primary--text"
              >
                <small>{{ item.sales_category.name }}</small>
              </v-chip>

              <v-chip
                v-if="item.purchases_category"
                small
                color="warning"
                class="v-chip-light-bg font-weight-semibold warning--text"
              >
                <small>{{ item.purchases_category.name }}</small>
              </v-chip>

              <v-spacer></v-spacer>
              <div class="fixed-expand-action">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="fetchItem(item.id); isSaveDialogOpen = true; showPurchasesDetailsField = false">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span class="caption">Edit</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="localItem = item; isDeleteDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span class="caption">Delete</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <p
              class="text-wrap"
              v-html="item.description"
            ></p>
          </td>
        </template>
      </v-data-table>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Remove {{ localItem.name }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false; localItem = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <template v-if="localItem.sales_category && localItem.purchases_category">
            <v-card-text>
              Remove this item from either sales or purchases category
              <v-radio-group
                v-model="removeCategoryType"
                row
                hide-details
              >
                <v-radio
                  v-if="localItem.sales_category"
                  :label="localItem.sales_category.name"
                  value="sales"
                  color="error"
                ></v-radio>
                <v-radio
                  v-if="localItem.purchases_category"
                  :label="localItem.purchases_category.name"
                  value="purchases"
                  color="error"
                ></v-radio>
              </v-radio-group>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="localItem.sales_category || localItem.purchases_category"
                color="error"
                block
                :loading="removeItemLoading"
                :disabled="removeItemLoading || !removeCategoryType"
                class="mt-3"
                @click="removeCategory()"
              >
                Remove from {{ removeCategoryType === 'sales' ? localItem.sales_category.name : localItem.purchases_category.name }}
              </v-btn>
            </v-card-actions>

            <div class="d-flex align-center mb-4">
              <v-divider></v-divider>
              <p class="mb-0 mx-5">
                OR
              </p>
              <v-divider></v-divider>
            </div>
          </template>

          <v-card-text>
            Completely remove this item from all categories. It will no longer appear in searches or available for use.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deleteItemLoading"
              :disabled="deleteItemLoading"
              class="mt-3"
              @click="deleteItem(localItem.id)"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Save category dialog -->
      <v-dialog
        v-model="isSaveCategoryDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center">
            New {{ categoryType }} category
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isSaveCategoryDialogOpen = false; category = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="saveCategoryForm"
              v-model="saveCategoryValid"
            >
              <v-text-field
                v-model="category.name"
                outlined
                dense
                label="Name"
                :rules="[validators.required]"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
              <v-autocomplete
                v-model="category.category_group_id"
                label="Category Group"
                :items="categoryGroups"
                item-text="name"
                item-value="id"
                single-line
                outlined
                dense
                hide-details
                return-object
                class="mb-6"
                @change="category.category_group_id = $event.id"
              ></v-autocomplete>
              <v-textarea
                v-model="category.description"
                outlined
                rows="3"
                label="Description"
                hide-details="auto"
                class="mb-6"
              ></v-textarea>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="saveCategoryLoading"
              :disabled="saveCategoryLoading || !saveCategoryValid"
              @click="saveCategory()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchItems"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const subscribed = inject('subscribed')
    const snackbarService = inject('snackbarService')

    // Properties
    const items = ref([])
    const salesCategories = ref([])
    const purchasesCategories = ref([])

    const searchText = ref('')
    const categoryFilter = ref(null)

    const localItem = ref({})
    const saveForm = ref(null)
    const saveValid = ref(false)
    const saveItemLoading = ref(false)
    const isSaveDialogOpen = ref(false)

    const category = ref({})
    const categoryType = ref(null)
    const categoryGroups = ref([])
    const saveCategoryForm = ref(null)
    const saveCategoryValid = ref(false)
    const saveCategoryLoading = ref(false)
    const isSaveCategoryDialogOpen = ref(false)
    const showPurchasesDetailsField = ref(false)

    const isDeleteDialogOpen = ref(false)
    const removeCategoryType = ref(null)
    const deleteItemLoading = ref(false)
    const removeItemLoading = ref(false)

    // Table Handlers
    const listLoading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['name'],
      sortDesc: [false],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        { text: 'CATEGORY', value: 'category', sortable: false },
        { text: 'DESCRIPTION', value: 'description', sortable: false },
        { text: 'UNIT PRICE', value: 'unit_price' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        { text: 'UNIT PRICE', value: 'unit_price' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Methods
    const fetchSalesCategories = () => {
      store
        .dispatch('clericalSettingsStore/fetchCategories', { no_pagination: true })
        .then(response => {
          salesCategories.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching categories. Please refresh!')
        })
    }
    const fetchPurchasesCategories = () => {
      store
        .dispatch('expensesSettingsStore/fetchCategories', { no_pagination: true })
        .then(response => {
          purchasesCategories.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching categories. Please refresh!')
        })
    }
    const fetchCategoryGroups = () => {
      store
        .dispatch('knowledgeBaseStore/fetchCategoryGroups', { type: categoryType.value })
        .then(response => {
          categoryGroups.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching category groups. Please refresh!')
        })
    }
    const fetchItems = () => {
      listLoading.value = true
      store
        .dispatch('clericalSettingsStore/fetchItems', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
          category_id: categoryFilter.value ? categoryFilter.value.id : null,
        })
        .then(response => {
          const { data, pagination } = response.data
          items.value = data
          tablePagination.value = pagination
          listLoading.value = false
        })
        .catch(error => {
          listLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching items. Please refresh!')
        })
    }
    const fetchItem = id => {
      store
        .dispatch('clericalSettingsStore/fetchItem', { id })
        .then(response => {
          localItem.value = response.data.data
          if (localItem.value.purchases_category_id) showPurchasesDetailsField.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching item. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchItems()
    }
    const saveItem = () => {
      saveItemLoading.value = true
      store
        .dispatch(`clericalSettingsStore/${localItem.value.id ? 'updateItem' : 'createItem'}`, localItem.value)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchItems()
          isSaveDialogOpen.value = false
          saveItemLoading.value = false
          saveForm.value.reset()
          localItem.value = {}
        })
        .catch(error => {
          saveItemLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding item. Please refresh!')
        })
    }
    const deleteItem = itemId => {
      deleteItemLoading.value = true
      store
        .dispatch('clericalSettingsStore/deleteItem', { id: itemId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchItems()
          isDeleteDialogOpen.value = false
          deleteItemLoading.value = false
          localItem.value = {}
        })
        .catch(error => {
          deleteItemLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting item. Please refresh!')
        })
    }
    const saveCategory = () => {
      saveCategoryLoading.value = true
      store
        .dispatch('clericalSettingsStore/createCategory', category.value)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchItems()
          isSaveCategoryDialogOpen.value = false
          saveCategoryLoading.value = false
          saveCategoryForm.value.reset()
          category.value = {}

          if (categoryType.value === 'sales') {
            fetchSalesCategories()
            localItem.value.sales_category_id = response.data.data.id
          }

          if (categoryType.value === 'purchases') {
            fetchPurchasesCategories()
            localItem.value.purchases_category_id = response.data.data.id
          }
        })
        .catch(error => {
          saveCategoryLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding category. Please refresh!')
        })
    }
    const removeCategory = () => {
      const params = { id: localItem.value.id }
      if (removeCategoryType.value === 'sales') {
        params.sales_category_id = ''
        params.unit_price = ''
      } else {
        params.purchases_category_id = ''
        params.cost_price = ''
      }

      removeItemLoading.value = true
      store
        .dispatch('clericalSettingsStore/updateItem', params)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchItems()
          isDeleteDialogOpen.value = false
          removeItemLoading.value = false
          localItem.value = {}
        })
        .catch(error => {
          removeItemLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while removing category. Please refresh!')
        })
    }

    // Watch
    let timer = null
    watch([searchText, categoryFilter, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchItems()
      }, 200)
    })

    // Mounted
    onMounted(() => {
      fetchSalesCategories()
      fetchPurchasesCategories()
    })

    return {
      // Initializers
      subscribed,

      // Properties
      items,
      salesCategories,
      purchasesCategories,

      searchText,
      categoryFilter,

      localItem,
      saveForm,
      saveValid,
      saveItemLoading,
      isSaveDialogOpen,

      category,
      categoryType,
      categoryGroups,
      saveCategoryForm,
      saveCategoryValid,
      saveCategoryLoading,
      isSaveCategoryDialogOpen,
      showPurchasesDetailsField,

      isDeleteDialogOpen,
      removeCategoryType,
      deleteItemLoading,
      removeItemLoading,

      // Table Handlers
      listLoading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
      },

      // Methods
      fetchSalesCategories,
      fetchCategoryGroups,
      fetchItems,
      fetchItem,
      listLengthChange,
      saveItem,
      deleteItem,
      saveCategory,
      removeCategory,
    }
  },
}
</script>
